import '../scss/manifest.scss';

import skipLinkFocusFix from './skip-link-focus-fix';

skipLinkFocusFix.init();

document.addEventListener('DOMContentLoaded', () => {
  // const galleryNav = document.querySelectorAll('.galerie-nav');
  const galleryLinks = document.querySelectorAll('ul.galerie-nav a');
  const galleries = document.querySelectorAll('.galerie-container');

  galleryLinks.forEach((link) => {
    link.addEventListener('click', (event) => {
      event.preventDefault();
      const href = event.target.getAttribute('href').substring(1);
      galleries.forEach((gallery) => {
        if (href === 'alle') {
          gallery.removeAttribute('hidden');
        } else if (href === gallery.id) {
          gallery.removeAttribute('hidden');
        } else {
          gallery.setAttribute('hidden', true);
        }
      });
    });
  });


  const socialMediaLinks = document.querySelectorAll('.social_links a');

  socialMediaLinks.forEach((link) => {
    link.setAttribute('target', '_blank');
  });
});
